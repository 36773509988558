import { ArrowLeft } from 'lucide-react';
import { Application } from '../../../types/application';
import { ApplicationStatusBadge } from '../ApplicationStatusBadge';
import { ApplicationActions } from './ApplicationActions';

interface ApplicationHeaderProps {
  application: Application;
  isCustomer: boolean;
  isAssignedAgent: boolean;
  canResubmit: boolean;
  updating: boolean;
  userType?: string;
  onBack: () => void;
  onCancel: () => Promise<void>;
  onResubmit: () => Promise<void>;
  onPickUp: () => Promise<void>;
  onUpdateStatus: (status: 'completed' | 'pending' | 'in_progress') => Promise<void>;
}

export function ApplicationHeader({
  application,
  isCustomer,
  isAssignedAgent,
  canResubmit,
  updating,
  userType,
  onBack,
  onCancel,
  onResubmit,
  onPickUp,
  onUpdateStatus
}: ApplicationHeaderProps) {
  const getBackText = () => {
    switch (userType) {
      case 'agent':
        return 'Back to Applications';
      case 'admin':
        return 'Back to Applications';
      default:
        return 'Back to Applications';
    }
  };

  return (
    <div className="mb-8">
      <button
        onClick={onBack}
        className="flex items-center text-gray-600 hover:text-gray-900"
      >
        <ArrowLeft className="h-5 w-5 mr-2" />
        {getBackText()}
      </button>
      
      <div className="mt-4">
        <h1 className="text-3xl font-bold text-gray-900">
          {application.position}
        </h1>
        <p className="mt-2 text-lg text-gray-600">
          {application.company}
        </p>
      </div>

      <div className="mt-4 flex justify-between items-center">
        <ApplicationStatusBadge status={application.status} />
        <ApplicationActions
          application={application}
          isCustomer={isCustomer}
          isAssignedAgent={isAssignedAgent}
          canResubmit={canResubmit}
          isAdmin={userType === 'admin'}
          updating={updating}
          onCancel={onCancel}
          onResubmit={onResubmit}
          onPickUp={onPickUp}
          onUpdateStatus={onUpdateStatus}
        />
      </div>
    </div>
  );
}