import { useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { useApplications } from '../hooks/useApplications';
import { Application } from '../types/application';
import { ClipboardList, Plus } from 'lucide-react';
import { Link } from 'react-router-dom';
import { ApplicationList } from '../components/applications/ApplicationList';
import { EmailVerificationBanner } from '../components/EmailVerificationBanner';
import { PendingApplicationBanner } from '../components/banners/PendingApplicationBanner';
import toast from 'react-hot-toast';

export function Applications() {
  const { user, userProfile } = useAuth();
  const { fetchUserApplications } = useApplications();
  const [applications, setApplications] = useState<Application[]>([]);
  const [loading, setLoading] = useState(true);
  const [hasPendingApplications, setHasPendingApplications] = useState(false);

  useEffect(() => {
    if (user) {
      loadApplications();
    }
  }, [user]);

  const loadApplications = async () => {
    if (!user) return;

    try {
      setLoading(true);
      const apps = await fetchUserApplications();
      
      // Sort applications by last update, most recent first
      const sortedApps = [...apps].sort((a, b) => 
        new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
      );
      
      setApplications(sortedApps);
      
      // Check for pending applications
      setHasPendingApplications(apps.some(app => app.status === 'pending'));
    } catch (error: any) {
      console.error('Error loading applications:', error);
      toast.error('Failed to load applications. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (!userProfile) return null;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      {!user?.emailVerified && <EmailVerificationBanner />}
      {hasPendingApplications && <PendingApplicationBanner />}

      <div className="mb-8">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-3xl font-bold text-gray-900">Applications</h1>
            <p className="mt-2 text-gray-600">
              Track and manage your job applications
            </p>
          </div>
          <Link
            to="/submit-application"
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
          >
            <Plus className="h-5 w-5 mr-2" />
            New Application
          </Link>
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center py-12">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-indigo-600"></div>
        </div>
      ) : applications.length > 0 ? (
        <ApplicationList 
          applications={applications} 
          onRefresh={loadApplications} 
        />
      ) : (
        <div className="text-center py-12 bg-white shadow rounded-lg">
          <ClipboardList className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">No applications</h3>
          <p className="mt-1 text-sm text-gray-500">
            Get started by submitting your first job application.
          </p>
          <div className="mt-6">
            <Link
              to="/submit-application"
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
            >
              <Plus className="h-5 w-5 mr-2" />
              New Application
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}