import { Link } from 'react-router-dom';

interface AuthLinksProps {
  isMobile?: boolean;
}

export function AuthLinks({ isMobile = false }: AuthLinksProps) {
  if (isMobile) {
    return (
      <>
        <Link
          to="/login"
          className="block px-3 py-2 text-base font-medium text-white hover:text-white hover:bg-gray-800"
        >
          Sign In
        </Link>
        <Link
          to="/register"
          className="block px-3 py-2 text-base font-medium text-white hover:text-white hover:bg-gray-800"
        >
          Register
        </Link>
      </>
    );
  }

  return (
    <div className="flex items-center space-x-4">
      <Link
        to="/login"
        className="text-white hover:text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-800"
      >
        Sign In
      </Link>
      <Link
        to="/register"
        className="bg-indigo-500 text-white hover:bg-indigo-600 px-4 py-2 rounded-md text-sm font-medium"
      >
        Register
      </Link>
    </div>
  );
}