import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { useApplications } from '../hooks/useApplications';
import { Application } from '../types/application';
import { Comments } from '../components/comments/Comments';
import { JobProfileDetails } from '../components/applications/details/JobProfileDetails';
import { ApplicationDetails } from '../components/applications/details/ApplicationDetails';
import { ApplicationHeader } from '../components/applications/details/ApplicationHeader';
import { SectionNav, sections } from '../components/applications/details/SectionNav';
import { useScrollSpy } from '../hooks/useScrollSpy';
import toast from 'react-hot-toast';

export function ApplicationDetailsPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user, userProfile } = useAuth();
  const { fetchApplicationById, updateApplication } = useApplications();
  const [application, setApplication] = useState<Application | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [updating, setUpdating] = useState(false);

  const activeSection = useScrollSpy(sections.map(s => s.id));

  useEffect(() => {
    if (user && id) {
      loadApplication();
    }
  }, [id, user]);

  const loadApplication = async () => {
    if (!id || !user) return;
    
    try {
      setLoading(true);
      setError(null);
      const app = await fetchApplicationById(id);
      setApplication(app);
    } catch (error: any) {
      console.error('Error loading application:', error);
      setError(error.message || 'Failed to load application details');
      if (error.message === 'Application not found') {
        toast.error('Application not found');
        navigate('/applications');
      } else {
        toast.error(error.message || 'Failed to load application details');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    if (userProfile?.userType === 'agent') {
      navigate('/agent/applications');
    } else if (userProfile?.userType === 'admin') {
      navigate('/admin/applications');
    } else {
      navigate('/applications');
    }
  };

  const handleCancel = async () => {
    if (!user || !application) return;

    try {
      setUpdating(true);
      await updateApplication(application.id, { status: 'cancelled' });
      toast.success('Application cancelled successfully');
      await loadApplication();
    } catch (error) {
      console.error('Error cancelling application:', error);
      toast.error('Failed to cancel application');
    } finally {
      setUpdating(false);
    }
  };

  const handleResubmit = async () => {
    if (!user || !application) return;

    try {
      setUpdating(true);
      await updateApplication(application.id, { status: 'resubmitted' });
      toast.success('Application resubmitted successfully');
      await loadApplication();
    } catch (error) {
      console.error('Error resubmitting application:', error);
      toast.error('Failed to resubmit application');
    } finally {
      setUpdating(false);
    }
  };

  const handlePickUp = async () => {
    if (!user || !application) return;

    try {
      setUpdating(true);
      await updateApplication(application.id, {
        status: 'in_progress',
        agentId: user.uid
      });
      toast.success('Application assigned to you');
      await loadApplication();
    } catch (error) {
      console.error('Error picking up application:', error);
      toast.error('Failed to pick up application');
    } finally {
      setUpdating(false);
    }
  };

  const handleUpdateStatus = async (newStatus: 'completed' | 'pending' | 'in_progress') => {
    if (!user || !application) return;

    try {
      setUpdating(true);
      await updateApplication(application.id, { status: newStatus });
      toast.success('Application status updated successfully');
      await loadApplication();
    } catch (error) {
      console.error('Error updating application:', error);
      toast.error('Failed to update application status');
    } finally {
      setUpdating(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center py-12">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  if (error || !application) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="bg-white shadow rounded-lg p-6">
          <div className="text-center">
            <h3 className="mt-2 text-lg font-medium text-gray-900">Error Loading Application</h3>
            <p className="mt-1 text-gray-500">{error || 'Application not found'}</p>
            <button
              onClick={handleBack}
              className="mt-4 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
            >
              Back to Applications
            </button>
          </div>
        </div>
      </div>
    );
  }

  const isCustomer = user?.uid === application.userId;
  const isAssignedAgent = userProfile?.userType === 'agent' && application.agentId === user?.uid;
  const canResubmit = isCustomer && application.status === 'pending';

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <ApplicationHeader
          application={application}
          isCustomer={isCustomer}
          isAssignedAgent={isAssignedAgent}
          canResubmit={canResubmit}
          updating={updating}
          userType={userProfile?.userType}
          onBack={handleBack}
          onCancel={handleCancel}
          onResubmit={handleResubmit}
          onPickUp={handlePickUp}
          onUpdateStatus={handleUpdateStatus}
        />

        <SectionNav
          activeSection={activeSection}
          onSectionClick={(sectionId) => {
            const element = document.getElementById(sectionId);
            if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
            }
          }}
        />

        <div className="space-y-8">
          <div id="details" className="bg-white shadow rounded-lg p-6">
            <h2 className="text-lg font-medium text-gray-900 mb-6">Application Details</h2>
            <ApplicationDetails application={application} />
          </div>

          <div id="profile" className="bg-white shadow rounded-lg p-6">
            <h2 className="text-lg font-medium text-gray-900 mb-6">Job Profile</h2>
            <JobProfileDetails profileId={application.profileId} />
          </div>

          <div id="comments" className="bg-white shadow rounded-lg p-6">
            <h2 className="text-lg font-medium text-gray-900 mb-6">Comments</h2>
            <Comments
              applicationId={application.id}
              onUpdate={loadApplication}
            />
          </div>
        </div>
      </div>
    </div>
  );
}