import { useState } from 'react';
import { UserProfile } from '../../hooks/useAuth';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { Coins } from 'lucide-react';
import { AdjustCreditsModal } from '../modals/AdjustCreditsModal';
import toast from 'react-hot-toast';

interface CustomersTableProps {
  customers: (UserProfile & { id: string })[];
  loading: boolean;
  onCustomerUpdated: () => void;
}

export function CustomersTable({ customers, loading, onCustomerUpdated }: CustomersTableProps) {
  const [selectedCustomer, setSelectedCustomer] = useState<UserProfile & { id: string } | null>(null);
  const [showCreditsModal, setShowCreditsModal] = useState(false);

  const handleAdjustCredits = async (amount: number) => {
    if (!selectedCustomer) return;

    try {
      const customerRef = doc(db, 'users', selectedCustomer.id);
      const currentCredits = selectedCustomer.credits || 0;
      const newCredits = currentCredits + amount;

      if (newCredits < 0) {
        throw new Error('Credits cannot be negative');
      }

      await updateDoc(customerRef, {
        credits: newCredits
      });

      toast.success(`Credits ${amount >= 0 ? 'added' : 'deducted'} successfully`);
      onCustomerUpdated();
    } catch (error: any) {
      toast.error(error.message || 'Failed to adjust credits');
    } finally {
      setShowCreditsModal(false);
      setSelectedCustomer(null);
    }
  };

  return (
    <div className="bg-white shadow rounded-lg overflow-hidden">
      <div className="overflow-x-auto">
        <div className="min-w-full inline-block align-middle">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                  Name
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                  Email
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                  Status
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                  Credits
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                  Joined
                </th>
                <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {loading ? (
                <tr>
                  <td colSpan={6} className="px-6 py-4 text-center">
                    <div className="flex justify-center">
                      <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-indigo-600"></div>
                    </div>
                  </td>
                </tr>
              ) : customers.length > 0 ? (
                customers.map((customer) => (
                  <tr key={customer.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {customer.firstName} {customer.lastName}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">{customer.email}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        customer.isVerified
                          ? 'bg-green-100 text-green-800'
                          : 'bg-yellow-100 text-yellow-800'
                      }`}>
                        {customer.isVerified ? 'Verified' : 'Pending'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {customer.credits || 0} CP
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {new Date(customer.createdAt).toLocaleDateString()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <button
                        onClick={() => {
                          setSelectedCustomer(customer);
                          setShowCreditsModal(true);
                        }}
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        <Coins className="h-5 w-5" />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="px-6 py-4 text-center text-sm text-gray-500">
                    No customers found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {selectedCustomer && (
        <AdjustCreditsModal
          isOpen={showCreditsModal}
          onClose={() => {
            setShowCreditsModal(false);
            setSelectedCustomer(null);
          }}
          onConfirm={handleAdjustCredits}
          customer={selectedCustomer}
        />
      )}
    </div>
  );
}