import { useAuth } from '../hooks/useAuth';
import { AdminDashboard } from '../components/dashboards/AdminDashboard';
import { CustomerDashboard } from '../components/dashboards/CustomerDashboard';
import { AgentDashboard } from '../components/dashboards/AgentDashboard';

export function Dashboard() {
  const { userProfile } = useAuth();

  if (!userProfile) return null;

  switch (userProfile.userType) {
    case 'admin':
      return <AdminDashboard />;
    case 'agent':
      return <AgentDashboard />;
    default:
      return <CustomerDashboard />;
  }
}