import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { 
  initializeFirestore, 
  persistentLocalCache,
  persistentMultipleTabManager,
  CACHE_SIZE_UNLIMITED,
  disableNetwork,
  enableNetwork,
  getFirestore
} from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import toast from 'react-hot-toast';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore with persistence
const db = initializeFirestore(app, {
  localCache: persistentLocalCache({
    tabManager: persistentMultipleTabManager(),
    cacheSizeBytes: CACHE_SIZE_UNLIMITED
  })
});

// Initialize secondary app for admin operations
const secondaryApp = initializeApp(firebaseConfig, 'secondary');

// Initialize services
export const auth = getAuth(app);
export const secondaryAuth = getAuth(secondaryApp);
export const storage = getStorage(app);

// Network status monitoring and handling
let isOnline = navigator.onLine;
let networkToastId: string | undefined;

async function updateOnlineStatus() {
  const previousStatus = isOnline;
  isOnline = navigator.onLine;
  
  if (previousStatus !== isOnline) {
    if (isOnline) {
      try {
        await enableNetwork(db);
        if (networkToastId) {
          toast.dismiss(networkToastId);
        }
        toast.success('Back online - Syncing data...', {
          id: 'online-status',
          duration: 3000
        });
      } catch (error) {
        console.error('Error enabling network:', error);
      }
    } else {
      try {
        await disableNetwork(db);
        networkToastId = toast.error(
          'You are offline. Some features may be limited, but you can still view cached data.',
          {
            id: 'offline-status',
            duration: Infinity
          }
        );
      } catch (error) {
        console.error('Error disabling network:', error);
      }
    }
  }
}

window.addEventListener('online', updateOnlineStatus);
window.addEventListener('offline', updateOnlineStatus);

// Initial check
updateOnlineStatus();

export { db, isOnline };