import * as React from 'react';
import { Tab } from '@headlessui/react';
import { cn } from '../../utils/cn';

interface TabsProps extends React.ComponentPropsWithoutRef<typeof Tab.Group> {
  value?: string;
  onValueChange?: (value: string) => void;
}

const TabsRoot = React.forwardRef<
  React.ElementRef<typeof Tab.Group>,
  TabsProps
>(({ className, value, onValueChange, defaultValue, ...props }, ref) => {
  const [selectedIndex, setSelectedIndex] = React.useState(() => {
    if (value !== undefined) {
      const index = React.Children.toArray(props.children)
        .filter((child: any) => child.type === TabsContent)
        .findIndex((child: any) => child.props.value === value);
      return Math.max(0, index);
    }
    if (defaultValue !== undefined) {
      const index = React.Children.toArray(props.children)
        .filter((child: any) => child.type === TabsContent)
        .findIndex((child: any) => child.props.value === defaultValue);
      return Math.max(0, index);
    }
    return 0;
  });

  React.useEffect(() => {
    if (value !== undefined) {
      const index = React.Children.toArray(props.children)
        .filter((child: any) => child.type === TabsContent)
        .findIndex((child: any) => child.props.value === value);
      if (index !== -1) {
        setSelectedIndex(index);
      }
    }
  }, [value, props.children]);

  return (
    <Tab.Group
      ref={ref}
      selectedIndex={selectedIndex}
      onChange={(index) => {
        setSelectedIndex(index);
        if (onValueChange) {
          const contents = React.Children.toArray(props.children)
            .filter((child: any) => child.type === TabsContent);
          const selectedContent = contents[index] as any;
          if (selectedContent?.props?.value) {
            onValueChange(selectedContent.props.value);
          }
        }
      }}
      {...props}
      className={cn('w-full', className)}
    />
  );
});
TabsRoot.displayName = 'TabsRoot';

const TabsList = React.forwardRef<
  React.ElementRef<typeof Tab.List>,
  React.ComponentPropsWithoutRef<typeof Tab.List>
>(({ className, ...props }, ref) => (
  <Tab.List
    ref={ref}
    className={cn(
      'inline-flex h-10 items-center justify-center rounded-lg bg-gray-100 p-1',
      className
    )}
    {...props}
  />
));
TabsList.displayName = 'TabsList';

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof Tab>,
  React.ComponentPropsWithoutRef<typeof Tab>
>(({ className, ...props }, ref) => (
  <Tab
    ref={ref}
    className={({ selected }) =>
      cn(
        'inline-flex items-center justify-center whitespace-nowrap rounded-md px-3 py-1.5 text-sm font-medium ring-offset-white transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-400 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
        selected
          ? 'bg-white text-gray-900 shadow-sm'
          : 'text-gray-500 hover:text-gray-900',
        className
      )
    }
    {...props}
  />
));
TabsTrigger.displayName = 'TabsTrigger';

const TabsContent = React.forwardRef<
  React.ElementRef<typeof Tab.Panel>,
  React.ComponentPropsWithoutRef<typeof Tab.Panel> & { value: string }
>(({ className, ...props }, ref) => (
  <Tab.Panel
    ref={ref}
    className={cn(
      'mt-2 ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-400 focus-visible:ring-offset-2',
      className
    )}
    {...props}
  />
));
TabsContent.displayName = 'TabsContent';

export { TabsRoot as Tabs, TabsList, TabsTrigger, TabsContent };