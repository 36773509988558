import { useNavigate } from 'react-router-dom';
import { Application } from '../../types/application';
import { ApplicationStatusBadge } from '../applications/ApplicationStatusBadge';
import { formatDate } from '../../utils/date';

interface ApplicationCardProps {
  application: Application;
  onRefresh: () => void;
}

export function ApplicationCard({ application, onRefresh }: ApplicationCardProps) {
  const navigate = useNavigate();

  return (
    <div 
      className="bg-white shadow rounded-lg p-6 hover:shadow-md transition-shadow duration-200 cursor-pointer"
      onClick={() => navigate(`/applications/${application.id}`)}
    >
      <div className="flex justify-between items-start">
        <div>
          <h3 className="text-lg font-medium text-gray-900">{application.position}</h3>
          <p className="text-sm text-gray-600">{application.company}</p>
          <div className="mt-2">
            <ApplicationStatusBadge status={application.status} />
          </div>
        </div>
        <div className="text-sm text-gray-500">
          {formatDate(application.updatedAt)}
        </div>
      </div>
    </div>
  );
}