import { useAuth } from '../hooks/useAuth';
import { CustomerProfile } from '../components/profiles/CustomerProfile';
import { StaffProfile } from '../components/profiles/StaffProfile';

export function Profile() {
  const { userProfile } = useAuth();

  if (!userProfile) return null;

  return userProfile.userType === 'customer' ? (
    <CustomerProfile />
  ) : (
    <StaffProfile />
  );
}