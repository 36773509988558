import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../lib/firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { FileText, Send, Clock, AlertTriangle, CheckCircle, XCircle } from 'lucide-react';
import { StatsCard } from '../components/cards/StatsCard';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../components/ui/Tabs';
import { AgentsTable } from '../components/tables/AgentsTable';
import { CustomersTable } from '../components/tables/CustomersTable';
import { AddAgentForm } from '../components/forms/AddAgentForm';
import { UserProfile } from '../hooks/useAuth';
import toast from 'react-hot-toast';

interface DashboardStats {
  total: number;
  submitted: number;
  inProgress: number;
  pending: number;
  completed: number;
  cancelled: number;
  avgFulfillmentTime: number;
}

const initialStats: DashboardStats = {
  total: 0,
  submitted: 0,
  inProgress: 0,
  pending: 0,
  completed: 0,
  cancelled: 0,
  avgFulfillmentTime: 0
};

export function AdminDashboard() {
  const navigate = useNavigate();
  const [stats, setStats] = useState<DashboardStats>(initialStats);
  const [agents, setAgents] = useState<(UserProfile & { id: string })[]>([]);
  const [customers, setCustomers] = useState<(UserProfile & { id: string })[]>([]);
  const [loading, setLoading] = useState(true);
  const [showAddAgentForm, setShowAddAgentForm] = useState(false);
  const [activeTab, setActiveTab] = useState('statistics');

  useEffect(() => {
    loadDashboardData();
  }, []);

  const loadDashboardData = async () => {
    try {
      setLoading(true);
      await Promise.all([
        loadApplicationStats(),
        loadAgents(),
        loadCustomers()
      ]);
    } catch (error) {
      console.error('Error loading dashboard data:', error);
      toast.error('Failed to load dashboard data');
    } finally {
      setLoading(false);
    }
  };

  const loadApplicationStats = async () => {
    try {
      const applicationsRef = collection(db, 'applications');
      const snapshot = await getDocs(applicationsRef);
      const applications = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Calculate statistics
      const total = applications.length;
      const submitted = applications.filter(app => app.status === 'submitted').length;
      const inProgress = applications.filter(app => app.status === 'in_progress').length;
      const pending = applications.filter(app => app.status === 'pending').length;
      const completed = applications.filter(app => app.status === 'completed').length;
      const cancelled = applications.filter(app => app.status === 'cancelled').length;

      // Calculate average fulfillment time
      const completedApps = applications.filter(app => app.status === 'completed' && app.completedAt);
      const totalFulfillmentTime = completedApps.reduce((acc, app) => {
        const fulfillmentTime = new Date(app.completedAt!).getTime() - new Date(app.createdAt).getTime();
        return acc + fulfillmentTime;
      }, 0);
      const avgFulfillmentTime = completedApps.length > 0 ? totalFulfillmentTime / completedApps.length : 0;

      setStats({
        total,
        submitted,
        inProgress,
        pending,
        completed,
        cancelled,
        avgFulfillmentTime
      });
    } catch (error) {
      console.error('Error loading application stats:', error);
      toast.error('Failed to load application statistics');
    }
  };

  const loadAgents = async () => {
    try {
      const agentsQuery = query(
        collection(db, 'users'),
        where('userType', '==', 'agent')
      );
      const snapshot = await getDocs(agentsQuery);
      const agentsList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as (UserProfile & { id: string })[];
      
      setAgents(agentsList);
    } catch (error) {
      console.error('Error loading agents:', error);
      toast.error('Failed to load agents');
    }
  };

  const loadCustomers = async () => {
    try {
      const customersQuery = query(
        collection(db, 'users'),
        where('userType', '==', 'customer')
      );
      const snapshot = await getDocs(customersQuery);
      const customersList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as (UserProfile & { id: string })[];
      
      setCustomers(customersList);
    } catch (error) {
      console.error('Error loading customers:', error);
      toast.error('Failed to load customers');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center py-12">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900">Admin Dashboard</h1>
        <p className="mt-2 text-gray-600">
          Monitor system activity and manage users
        </p>
      </div>

      <Tabs defaultValue={activeTab} className="space-y-8" as="div">
        <TabsList>
          <TabsTrigger value="statistics">Statistics</TabsTrigger>
          <TabsTrigger value="agents">Agents</TabsTrigger>
          <TabsTrigger value="customers">Customers</TabsTrigger>
        </TabsList>

        <TabsContent value="statistics">
          <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-6">
            <StatsCard
              title="Total Applications"
              value={stats.total.toString()}
              icon={FileText}
              variant="primary"
              linkTo="/admin/applications?status=all"
            />
            <StatsCard
              title="Submitted"
              value={stats.submitted.toString()}
              icon={Send}
              variant="info"
              linkTo="/admin/applications?status=submitted"
            />
            <StatsCard
              title="In Progress"
              value={stats.inProgress.toString()}
              icon={Clock}
              variant="warning"
              linkTo="/admin/applications?status=in_progress"
            />
            <StatsCard
              title="Pending Info"
              value={stats.pending.toString()}
              icon={AlertTriangle}
              variant="warning"
              linkTo="/admin/applications?status=pending"
            />
            <StatsCard
              title="Completed"
              value={stats.completed.toString()}
              icon={CheckCircle}
              variant="success"
              linkTo="/admin/applications?status=completed"
            />
            <StatsCard
              title="Cancelled"
              value={stats.cancelled.toString()}
              icon={XCircle}
              variant="danger"
              linkTo="/admin/applications?status=cancelled"
            />
          </div>

          {stats.avgFulfillmentTime > 0 && (
            <div className="mt-8 bg-white shadow rounded-lg p-6">
              <h2 className="text-lg font-medium text-gray-900">Average Fulfillment Time</h2>
              <p className="mt-2 text-3xl font-bold text-indigo-600">
                {Math.round(stats.avgFulfillmentTime / (1000 * 60 * 60 * 24))} days
              </p>
            </div>
          )}
        </TabsContent>

        <TabsContent value="agents">
          <div className="bg-white shadow rounded-lg p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold text-gray-900">Manage Agents</h2>
              <button
                onClick={() => setShowAddAgentForm(true)}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Add New Agent
              </button>
            </div>

            {showAddAgentForm && (
              <div className="mb-6">
                <AddAgentForm
                  onSuccess={() => {
                    setShowAddAgentForm(false);
                    loadAgents();
                  }}
                  onCancel={() => setShowAddAgentForm(false)}
                />
              </div>
            )}

            <AgentsTable
              agents={agents}
              loading={loading}
              onAgentDeleted={loadAgents}
            />
          </div>
        </TabsContent>

        <TabsContent value="customers">
          <div className="bg-white shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-6">Manage Customers</h2>
            <CustomersTable
              customers={customers}
              loading={loading}
              onCustomerUpdated={loadCustomers}
            />
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
}