import { useState, useEffect } from 'react';
import { db } from '../../../lib/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { FaRegFilePdf } from 'react-icons/fa6';
import toast from 'react-hot-toast';

interface JobProfileDetailsProps {
  profileId: string;
}

export function JobProfileDetails({ profileId }: JobProfileDetailsProps) {
  const [profile, setProfile] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadProfile();
  }, [profileId]);

  const loadProfile = async () => {
    try {
      const docRef = doc(db, 'jobProfiles', profileId);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        setProfile(docSnap.data());
      }
    } catch (error) {
      console.error('Error loading profile:', error);
      toast.error('Failed to load job profile details');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center py-12">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  if (!profile) {
    return (
      <div className="bg-white shadow rounded-lg p-6">
        <p className="text-gray-500 text-center">Profile not found</p>
      </div>
    );
  }

  return (
    <div className="bg-white shadow rounded-lg divide-y divide-gray-200">
      <div className="px-6 py-4">
        <h3 className="text-lg font-medium text-gray-900">
          {profile.profileName}
        </h3>
      </div>

      <div className="px-6 py-4">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
          <div>
            <dt className="text-sm font-medium text-gray-500">First Name</dt>
            <dd className="mt-1 text-sm text-gray-900">{profile.firstName}</dd>
          </div>

          {profile.middleName && (
            <div>
              <dt className="text-sm font-medium text-gray-500">Middle Name</dt>
              <dd className="mt-1 text-sm text-gray-900">{profile.middleName}</dd>
            </div>
          )}

          <div>
            <dt className="text-sm font-medium text-gray-500">Last Name</dt>
            <dd className="mt-1 text-sm text-gray-900">{profile.lastName}</dd>
          </div>

          <div>
            <dt className="text-sm font-medium text-gray-500">Phone Number</dt>
            <dd className="mt-1 text-sm text-gray-900">{profile.phoneNumber}</dd>
          </div>
        </dl>
      </div>

      {profile.resumeURL && (
        <div className="px-6 py-4">
          <dt className="text-sm font-medium text-gray-500 mb-2">Resume</dt>
          <dd>
            <a
              href={profile.resumeURL}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center space-x-2 p-3 bg-gray-50 rounded-md hover:bg-gray-100 transition-colors duration-200 group"
            >
              <FaRegFilePdf className="h-6 w-6 text-red-500 group-hover:text-red-600" />
              <span className="text-sm font-medium text-gray-900 group-hover:text-indigo-600">
                {profile.resumeFileName}
              </span>
            </a>
          </dd>
        </div>
      )}
    </div>
  );
}