import { useState, useEffect } from 'react';
import { useAuth } from './useAuth';
import { db } from '../lib/firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { Application } from '../types/application';

interface AgentStats {
  totalApplications: number;
  completedApplications: number;
  pendingApplications: number;
  inProgressApplications: number;
  averageFulfillmentTime: number;
  completionRate: number;
  todayCompletions: number;
  weeklyCompletions: number;
}

export function useAgentStats() {
  const { user } = useAuth();
  const [stats, setStats] = useState<AgentStats>({
    totalApplications: 0,
    completedApplications: 0,
    pendingApplications: 0,
    inProgressApplications: 0,
    averageFulfillmentTime: 0,
    completionRate: 0,
    todayCompletions: 0,
    weeklyCompletions: 0
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      loadStats();
    }
  }, [user]);

  const loadStats = async () => {
    if (!user) return;

    try {
      setLoading(true);
      const q = query(
        collection(db, 'applications'),
        where('agentId', '==', user.uid)
      );
      const snapshot = await getDocs(q);
      const applications = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Application[];

      // Calculate basic stats
      const completed = applications.filter(app => app.status === 'completed');
      const pending = applications.filter(app => app.status === 'pending');
      const inProgress = applications.filter(app => app.status === 'in_progress');
      
      // Calculate fulfillment times for completed applications
      const fulfillmentTimes = completed
        .filter(app => app.fulfillmentTime)
        .map(app => app.fulfillmentTime as number);
      
      const avgFulfillmentTime = fulfillmentTimes.length > 0
        ? fulfillmentTimes.reduce((acc, time) => acc + time, 0) / fulfillmentTimes.length
        : 0;

      // Calculate completion rate
      const completionRate = applications.length > 0
        ? (completed.length / applications.length) * 100
        : 0;

      // Calculate today's and weekly completions
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const weekAgo = new Date(today);
      weekAgo.setDate(weekAgo.getDate() - 7);

      const todayCompletions = completed.filter(app => {
        const completedDate = new Date(app.completedAt || '');
        return completedDate >= today;
      }).length;

      const weeklyCompletions = completed.filter(app => {
        const completedDate = new Date(app.completedAt || '');
        return completedDate >= weekAgo;
      }).length;

      setStats({
        totalApplications: applications.length,
        completedApplications: completed.length,
        pendingApplications: pending.length,
        inProgressApplications: inProgress.length,
        averageFulfillmentTime: avgFulfillmentTime,
        completionRate,
        todayCompletions,
        weeklyCompletions
      });
    } catch (error) {
      console.error('Error loading agent stats:', error);
    } finally {
      setLoading(false);
    }
  };

  return { stats, loading, refresh: loadStats };
}