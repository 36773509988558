import { useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { db } from '../lib/firebase';
import { collection, query, where, orderBy, getDocs, limit } from 'firebase/firestore';
import { Application } from '../types/application';
import { ApplicationCard } from '../components/cards/ApplicationCard';
import { ClipboardList } from 'lucide-react';

export function AgentApplications() {
  const { user } = useAuth();
  const [currentApplications, setCurrentApplications] = useState<Application[]>([]);
  const [pendingApplications, setPendingApplications] = useState<Application[]>([]);
  const [availableApplications, setAvailableApplications] = useState<Application[]>([]);
  const [lastCompletedApplication, setLastCompletedApplication] = useState<Application | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      loadApplications();
    }
  }, [user]);

  const loadApplications = async () => {
    if (!user) return;

    try {
      setLoading(true);
      
      // Query for submitted applications that haven't been picked up yet
      const availableQuery = query(
        collection(db, 'applications'),
        where('status', '==', 'submitted'),
        orderBy('createdAt', 'desc')
      );

      // Query for applications in progress by this agent
      const currentQuery = query(
        collection(db, 'applications'),
        where('agentId', '==', user.uid),
        where('status', '==', 'in_progress'),
        orderBy('updatedAt', 'desc')
      );

      // Query for pending/resubmitted applications assigned to this agent
      const pendingQuery = query(
        collection(db, 'applications'),
        where('agentId', '==', user.uid),
        where('status', 'in', ['pending', 'resubmitted']),
        orderBy('updatedAt', 'desc')
      );

      // Query for last completed application by this agent
      const lastCompletedQuery = query(
        collection(db, 'applications'),
        where('agentId', '==', user.uid),
        where('status', '==', 'completed'),
        orderBy('updatedAt', 'desc'),
        limit(1)
      );

      const [availableSnapshot, currentSnapshot, pendingSnapshot, lastCompletedSnapshot] = 
        await Promise.all([
          getDocs(availableQuery),
          getDocs(currentQuery),
          getDocs(pendingQuery),
          getDocs(lastCompletedQuery)
        ]);

      setAvailableApplications(
        availableSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Application[]
      );

      setCurrentApplications(
        currentSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Application[]
      );

      setPendingApplications(
        pendingSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Application[]
      );

      // Set last completed application if exists
      if (!lastCompletedSnapshot.empty) {
        setLastCompletedApplication({
          id: lastCompletedSnapshot.docs[0].id,
          ...lastCompletedSnapshot.docs[0].data()
        } as Application);
      }
    } catch (error) {
      console.error('Error loading applications:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center py-12">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900">Applications</h1>
        <p className="mt-2 text-gray-600">
          View and process job applications
        </p>
      </div>

      {/* Current Applications Section */}
      <div className="mb-12">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-semibold text-gray-900">Current Applications</h2>
          <span className="px-3 py-1 text-sm font-medium text-indigo-600 bg-indigo-50 rounded-full">
            {currentApplications.length} Active
          </span>
        </div>

        {currentApplications.length > 0 ? (
          <div className="space-y-4">
            {currentApplications.map(application => (
              <ApplicationCard
                key={application.id}
                application={application}
                onRefresh={loadApplications}
              />
            ))}
          </div>
        ) : (
          <div className="text-center py-12 bg-white shadow rounded-lg">
            <ClipboardList className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">No current applications</h3>
            <p className="mt-1 text-sm text-gray-500">
              You have no applications in progress.
            </p>
          </div>
        )}
      </div>

      {/* Pending/Resubmitted Applications Section */}
      <div className="mb-12">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-semibold text-gray-900">Pending & Resubmitted Applications</h2>
          <span className="px-3 py-1 text-sm font-medium text-orange-600 bg-orange-50 rounded-full">
            {pendingApplications.length} Pending
          </span>
        </div>

        {pendingApplications.length > 0 ? (
          <div className="space-y-4">
            {pendingApplications.map(application => (
              <ApplicationCard
                key={application.id}
                application={application}
                onRefresh={loadApplications}
              />
            ))}
          </div>
        ) : (
          <div className="text-center py-12 bg-white shadow rounded-lg">
            <ClipboardList className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">No pending applications</h3>
            <p className="mt-1 text-sm text-gray-500">
              You have no applications waiting for additional information.
            </p>
          </div>
        )}
      </div>

      {/* Available Applications Section */}
      <div className="mb-12">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-semibold text-gray-900">Available Applications</h2>
          <span className="px-3 py-1 text-sm font-medium text-green-600 bg-green-50 rounded-full">
            {availableApplications.length} Available
          </span>
        </div>

        {availableApplications.length > 0 ? (
          <div className="space-y-4">
            {availableApplications.map(application => (
              <ApplicationCard
                key={application.id}
                application={application}
                onRefresh={loadApplications}
              />
            ))}
          </div>
        ) : (
          <div className="text-center py-12 bg-white shadow rounded-lg">
            <ClipboardList className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">No applications available</h3>
            <p className="mt-1 text-sm text-gray-500">
              Check back later for new applications to process.
            </p>
          </div>
        )}
      </div>

      {/* Last Completed Application Section */}
      {lastCompletedApplication && (
        <div>
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-xl font-semibold text-gray-900">Last Completed Application</h2>
          </div>
          <ApplicationCard
            application={lastCompletedApplication}
            onRefresh={loadApplications}
          />
        </div>
      )}
    </div>
  );
}