import { useState } from 'react';
import { Trash2, Send, Key, ToggleLeft, ToggleRight } from 'lucide-react';
import { UserProfile } from '../../hooks/useAuth';
import { auth, db, secondaryAuth } from '../../lib/firebase';
import { sendPasswordResetEmail, deleteUser, signInWithEmailAndPassword } from 'firebase/auth';
import { doc, updateDoc, deleteDoc } from 'firebase/firestore';
import toast from 'react-hot-toast';
import { ConfirmDeleteAgentModal } from '../modals/ConfirmDeleteAgentModal';
import { ChangeAgentPasswordModal } from '../modals/ChangeAgentPasswordModal';

interface AgentsTableProps {
  agents: (UserProfile & { id: string })[];
  loading: boolean;
  onAgentDeleted: () => void;
}

export function AgentsTable({ agents, loading, onAgentDeleted }: AgentsTableProps) {
  const [agentToDelete, setAgentToDelete] = useState<UserProfile & { id: string } | null>(null);
  const [agentToChangePassword, setAgentToChangePassword] = useState<UserProfile & { id: string } | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [updatingStatus, setUpdatingStatus] = useState<string | null>(null);

  const handleSendPasswordReset = async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email);
      toast.success('Password reset email sent successfully');
    } catch (error) {
      toast.error('Failed to send password reset email');
    }
  };

  const handleDeleteAgent = async (password: string) => {
    if (!agentToDelete) return;

    try {
      await deleteDoc(doc(db, 'users', agentToDelete.id));
      const userCredential = await signInWithEmailAndPassword(
        secondaryAuth,
        agentToDelete.email,
        password
      );
      await deleteUser(userCredential.user);
      toast.success('Agent deleted successfully');
      onAgentDeleted();
    } catch (error: any) {
      console.error('Error deleting agent:', error);
      toast.error(error.message || 'Failed to delete agent');
    } finally {
      setAgentToDelete(null);
      setShowDeleteModal(false);
    }
  };

  const handleToggleStatus = async (agent: UserProfile & { id: string }) => {
    try {
      setUpdatingStatus(agent.id);
      await updateDoc(doc(db, 'users', agent.id), {
        isKaput: !agent.isKaput
      });
      toast.success(`Agent ${agent.isKaput ? 'reactivated' : 'decommissioned'} successfully`);
      onAgentDeleted();
    } catch (error) {
      console.error('Error updating agent status:', error);
      toast.error('Failed to update agent status');
    } finally {
      setUpdatingStatus(null);
    }
  };

  return (
    <div className="bg-white shadow rounded-lg overflow-hidden">
      <div className="px-4 py-5 sm:px-6">
        <h2 className="text-lg font-medium text-gray-900">Agents</h2>
      </div>
      <div className="border-t border-gray-200 overflow-x-auto">
        <div className="min-w-full inline-block align-middle">
          {loading ? (
            <div className="p-4 flex justify-center">
              <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-indigo-600"></div>
            </div>
          ) : agents.length > 0 ? (
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                    Email
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                    Phone
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                    Joined
                  </th>
                  <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {agents.map((agent) => (
                  <tr key={agent.id} className={agent.isKaput ? 'bg-gray-50' : ''}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {agent.firstName} {agent.lastName}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">{agent.email}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">{agent.phoneNumber}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          agent.isVerified
                            ? 'bg-green-100 text-green-800'
                            : 'bg-yellow-100 text-yellow-800'
                        }`}>
                          {agent.isVerified ? 'Verified' : 'Pending'}
                        </span>
                        {agent.isKaput && (
                          <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                            Decommissioned
                          </span>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {new Date(agent.createdAt).toLocaleDateString()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <div className="flex items-center justify-end space-x-2">
                        <button
                          className="text-indigo-600 hover:text-indigo-900"
                          onClick={() => handleSendPasswordReset(agent.email)}
                          title="Send password reset email"
                        >
                          <Send className="h-5 w-5" />
                        </button>
                        <button
                          className="text-indigo-600 hover:text-indigo-900"
                          onClick={() => {
                            setAgentToChangePassword(agent);
                            setShowPasswordModal(true);
                          }}
                          title="Change password"
                        >
                          <Key className="h-5 w-5" />
                        </button>
                        <button
                          className={`${agent.isKaput ? 'text-green-600 hover:text-green-900' : 'text-yellow-600 hover:text-yellow-900'}`}
                          onClick={() => handleToggleStatus(agent)}
                          disabled={updatingStatus === agent.id}
                          title={agent.isKaput ? 'Reactivate agent' : 'Decommission agent'}
                        >
                          {agent.isKaput ? (
                            <ToggleRight className="h-5 w-5" />
                          ) : (
                            <ToggleLeft className="h-5 w-5" />
                          )}
                        </button>
                        <button
                          className="text-red-600 hover:text-red-900"
                          onClick={() => {
                            setAgentToDelete(agent);
                            setShowDeleteModal(true);
                          }}
                          title="Delete agent"
                        >
                          <Trash2 className="h-5 w-5" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="p-4 text-center text-gray-500">
              No agents found. Add your first agent using the button above.
            </div>
          )}
        </div>
      </div>

      {agentToDelete && (
        <ConfirmDeleteAgentModal
          isOpen={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
            setAgentToDelete(null);
          }}
          onConfirm={handleDeleteAgent}
          agent={agentToDelete}
        />
      )}

      {agentToChangePassword && (
        <ChangeAgentPasswordModal
          isOpen={showPasswordModal}
          onClose={() => {
            setShowPasswordModal(false);
            setAgentToChangePassword(null);
          }}
          onConfirm={async (newPassword) => {
            try {
              await sendPasswordResetEmail(auth, agentToChangePassword.email);
              toast.success('Password reset email sent successfully');
              setShowPasswordModal(false);
            } catch (error) {
              toast.error('Failed to send password reset email');
            }
          }}
          agent={agentToChangePassword}
        />
      )}
    </div>
  );
}