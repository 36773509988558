import { openDB, DBSchema, IDBPDatabase } from 'idb';

interface BlitzApplyDB extends DBSchema {
  applications: {
    key: string;
    value: {
      id: string;
      lastSync: string;
      data: any;
    };
    indexes: { 'by-status': string };
  };
  comments: {
    key: string;
    value: {
      id: string;
      applicationId: string;
      lastSync: string;
      data: any;
    };
    indexes: { 'by-application': string };
  };
}

let db: IDBPDatabase<BlitzApplyDB>;

export async function initDB() {
  db = await openDB<BlitzApplyDB>('blitzapply', 1, {
    upgrade(db) {
      // Applications store
      const applicationStore = db.createObjectStore('applications', {
        keyPath: 'id'
      });
      applicationStore.createIndex('by-status', 'data.status');

      // Comments store
      const commentsStore = db.createObjectStore('comments', {
        keyPath: 'id'
      });
      commentsStore.createIndex('by-application', 'applicationId');
    }
  });
  return db;
}

export async function getDB() {
  if (!db) {
    db = await initDB();
  }
  return db;
}