import { useState, useEffect } from 'react';
import { db } from '../../lib/firebase';
import { collection, query, where, getDocs, limit } from 'firebase/firestore';
import { UserProfile } from '../../hooks/useAuth';
import { Plus, Users } from 'lucide-react';
import { Link } from 'react-router-dom';
import { AddAgentForm } from '../forms/AddAgentForm';
import { AgentsTable } from '../tables/AgentsTable';
import { CustomersTable } from '../tables/CustomersTable';
import { StatsCard } from '../cards/StatsCard';
import { Application } from '../../types/application';

export function AdminDashboard() {
  const [agents, setAgents] = useState<(UserProfile & { id: string })[]>([]);
  const [customers, setCustomers] = useState<(UserProfile & { id: string })[]>([]);
  const [showAddAgentForm, setShowAddAgentForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    submitted: 0,
    inProgress: 0,
    pending: 0,
    completed: 0,
    cancelled: 0,
    total: 0
  });

  useEffect(() => {
    loadStats();
    loadAgents();
    loadCustomers();
  }, []);

  async function loadStats() {
    try {
      const applicationsRef = collection(db, 'applications');
      const snapshot = await getDocs(applicationsRef);
      const applications = snapshot.docs.map(doc => doc.data() as Application);

      setStats({
        submitted: applications.filter(app => app.status === 'submitted').length,
        inProgress: applications.filter(app => app.status === 'in_progress').length,
        pending: applications.filter(app => app.status === 'pending').length,
        completed: applications.filter(app => app.status === 'completed').length,
        cancelled: applications.filter(app => app.status === 'cancelled').length,
        total: applications.length
      });
    } catch (error) {
      console.error('Error loading stats:', error);
    }
  }

  async function loadAgents() {
    try {
      const q = query(
        collection(db, 'users'), 
        where('userType', '==', 'agent'),
        limit(5)
      );
      const querySnapshot = await getDocs(q);
      const agentsList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as (UserProfile & { id: string })[];
      
      setAgents(agentsList);
    } catch (error) {
      console.error('Error loading agents:', error);
    }
  }

  async function loadCustomers() {
    try {
      const q = query(
        collection(db, 'users'),
        where('userType', '==', 'customer'),
        limit(5)
      );
      const querySnapshot = await getDocs(q);
      const customersList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as (UserProfile & { id: string })[];
      
      setCustomers(customersList);
      setLoading(false);
    } catch (error) {
      console.error('Error loading customers:', error);
      setLoading(false);
    }
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900">Admin Dashboard</h1>
        <p className="mt-2 text-gray-600">
          Monitor system activity and manage users
        </p>
      </div>

      {/* Applications Statistics */}
      <div className="mb-12">
        <h2 className="text-xl font-semibold text-gray-900 mb-6">Applications Overview</h2>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6">
          <StatsCard
            title="Total Applications"
            value={stats.total.toString()}
            variant="primary"
          />
          <StatsCard
            title="Submitted"
            value={stats.submitted.toString()}
            variant="info"
          />
          <StatsCard
            title="In Progress"
            value={stats.inProgress.toString()}
            variant="warning"
          />
          <StatsCard
            title="Pending Info"
            value={stats.pending.toString()}
            variant="warning"
          />
          <StatsCard
            title="Completed"
            value={stats.completed.toString()}
            variant="success"
          />
          <StatsCard
            title="Cancelled"
            value={stats.cancelled.toString()}
            variant="danger"
          />
        </div>
      </div>

      {/* Agents Section */}
      <div className="mb-12">
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center">
            <h2 className="text-xl font-semibold text-gray-900">Agents</h2>
            <Link 
              to="/admin/agents"
              className="ml-4 text-sm text-indigo-600 hover:text-indigo-500"
            >
              View All
            </Link>
          </div>
          <button
            onClick={() => setShowAddAgentForm(true)}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
          >
            <Plus className="h-5 w-5 mr-2" />
            Add New Agent
          </button>
        </div>

        {showAddAgentForm && (
          <div className="mb-8">
            <AddAgentForm
              onSuccess={() => {
                setShowAddAgentForm(false);
                loadAgents();
              }}
              onCancel={() => setShowAddAgentForm(false)}
            />
          </div>
        )}

        <AgentsTable agents={agents} loading={loading} onAgentDeleted={loadAgents} />
      </div>

      {/* Customers Section */}
      <div>
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center">
            <h2 className="text-xl font-semibold text-gray-900">Customers</h2>
            <Link 
              to="/admin/customers"
              className="ml-4 text-sm text-indigo-600 hover:text-indigo-500"
            >
              View All
            </Link>
          </div>
          <span className="text-sm text-gray-500">
            Showing {customers.length} of {customers.length} customers
          </span>
        </div>

        <CustomersTable 
          customers={customers} 
          loading={loading} 
          onCustomerUpdated={loadCustomers}
        />
      </div>
    </div>
  );
}