import { useNavigate } from 'react-router-dom';
import { Application } from '../../types/application';
import { ApplicationStatusBadge } from './ApplicationStatusBadge';
import { formatDate } from '../../utils/date';
import { ExternalLink } from 'lucide-react';

interface ApplicationCardProps {
  application: Application;
  onRefresh?: () => void;
}

export function ApplicationCard({ application }: ApplicationCardProps) {
  const navigate = useNavigate();

  return (
    <div 
      onClick={() => navigate(`/applications/${application.id}`)}
      className="bg-white shadow rounded-lg p-6 hover:shadow-md transition-shadow duration-200 cursor-pointer"
    >
      <div className="flex justify-between items-start">
        <div className="flex-1">
          <h3 className="text-lg font-medium text-gray-900">{application.position}</h3>
          <div className="mt-1 flex items-center space-x-2">
            <p className="text-sm text-gray-600">{application.company}</p>
            <a
              href={application.jobUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()}
              className="text-sm text-indigo-600 hover:text-indigo-500 inline-flex items-center"
            >
              <ExternalLink className="h-4 w-4 ml-1" />
              <span className="sr-only">View job posting</span>
            </a>
          </div>
          <div className="mt-4 flex items-center space-x-4">
            <ApplicationStatusBadge status={application.status} />
            {application.agentName && (
              <span className="text-sm text-gray-500">
                Agent: {application.agentName}
              </span>
            )}
          </div>
        </div>
        <div className="text-sm text-gray-500">
          {formatDate(application.updatedAt)}
        </div>
      </div>
      
      {application.status === 'pending' && application.notes && (
        <div className="mt-4 text-sm text-gray-600 bg-yellow-50 p-3 rounded-md">
          <p className="font-medium text-yellow-800">Additional information needed</p>
          <p className="mt-1">{application.notes}</p>
        </div>
      )}
    </div>
  );
}