import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Coins } from 'lucide-react';
import { UserProfile } from '../../hooks/useAuth';

interface AdjustCreditsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (amount: number) => Promise<void>;
  customer: UserProfile & { id: string };
}

export function AdjustCreditsModal({
  isOpen,
  onClose,
  onConfirm,
  customer
}: AdjustCreditsModalProps) {
  const [amount, setAmount] = useState('0');
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    try {
      setLoading(true);
      await onConfirm(parseInt(amount));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="flex items-center text-lg font-medium leading-6 text-gray-900"
                >
                  <Coins className="h-6 w-6 text-indigo-500 mr-2" />
                  Adjust Credits
                </Dialog.Title>

                <div className="mt-4">
                  <p className="text-sm text-gray-500">
                    Adjust credits for {customer.firstName} {customer.lastName}
                  </p>
                  <p className="text-sm text-gray-500 mt-1">
                    Current balance: {customer.credits || 0} CP
                  </p>

                  <div className="mt-4">
                    <label htmlFor="amount" className="block text-sm font-medium text-gray-700">
                      Amount (use negative values to deduct)
                    </label>
                    <input
                      type="number"
                      id="amount"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      placeholder="Enter amount"
                    />
                    <p className="mt-2 text-sm text-gray-500">
                      New balance will be: {(customer.credits || 0) + parseInt(amount || '0')} CP
                    </p>
                  </div>
                </div>

                <div className="mt-6 flex justify-end space-x-3">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-700 disabled:opacity-50"
                    onClick={handleConfirm}
                    disabled={loading || !amount || parseInt(amount) === 0}
                  >
                    {loading ? 'Adjusting...' : 'Adjust Credits'}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}