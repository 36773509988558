import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { useAuth } from './hooks/useAuth';
import { PrivateRoute } from './components/PrivateRoute';
import { AdminRoute } from './components/AdminRoute';
import { Navbar } from './components/Navbar';
import { OfflineBanner } from './components/OfflineBanner';
import { Home } from './pages/Home';
import { Login } from './pages/Login';
import { Register } from './pages/Register';
import { Dashboard } from './pages/Dashboard';
import { Profile } from './pages/Profile';
import { Applications } from './pages/Applications';
import { JobProfiles } from './pages/JobProfiles';
import { CreateJobProfile } from './pages/CreateJobProfile';
import { JobProfileForm } from './pages/JobProfileForm';
import { SubmitApplication } from './pages/SubmitApplication';
import { ApplicationDetailsPage } from './pages/ApplicationDetailsPage';
import { Credits } from './pages/Credits';
import { AgentApplications } from './pages/AgentApplications';
import { AgentDashboard } from './components/dashboards/AgentDashboard';
import { AdminApplications } from './pages/AdminApplications';
import { AdminDashboard } from './pages/AdminDashboard';

export function App() {
  const { user, userProfile } = useAuth();
  const [isOffline, setIsOffline] = useState(!navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOffline(false);
    const handleOffline = () => setIsOffline(true);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  // Redirect to appropriate dashboard based on user type
  const getDashboardRedirect = () => {
    if (!user || !userProfile) return '/';
    switch (userProfile.userType) {
      case 'admin':
        return '/admin/dashboard';
      case 'agent':
        return '/agent/dashboard';
      default:
        return '/dashboard';
    }
  };

  return (
    <Router>
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
        <Navbar />
        {isOffline && <OfflineBanner />}
        <Routes>
          <Route path="/" element={user ? <Navigate to={getDashboardRedirect()} /> : <Home />} />
          <Route path="/login" element={user ? <Navigate to={getDashboardRedirect()} /> : <Login />} />
          <Route path="/register" element={user ? <Navigate to={getDashboardRedirect()} /> : <Register />} />
          
          <Route path="/dashboard" element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          } />
          
          <Route path="/profile" element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          } />
          
          <Route path="/applications" element={
            <PrivateRoute>
              <Applications />
            </PrivateRoute>
          } />
          
          <Route path="/applications/:id" element={
            <PrivateRoute>
              <ApplicationDetailsPage />
            </PrivateRoute>
          } />
          
          <Route path="/job-profiles" element={
            <PrivateRoute>
              <JobProfiles />
            </PrivateRoute>
          } />
          
          <Route path="/job-profiles/new" element={
            <PrivateRoute>
              <CreateJobProfile />
            </PrivateRoute>
          } />
          
          <Route path="/job-profiles/:id/edit" element={
            <PrivateRoute>
              <JobProfileForm />
            </PrivateRoute>
          } />
          
          <Route path="/submit-application" element={
            <PrivateRoute>
              <SubmitApplication />
            </PrivateRoute>
          } />

          <Route path="/credits" element={
            <PrivateRoute>
              <Credits />
            </PrivateRoute>
          } />

          <Route path="/agent/dashboard" element={
            <PrivateRoute>
              <AgentDashboard />
            </PrivateRoute>
          } />

          <Route path="/agent/applications" element={
            <PrivateRoute>
              <AgentApplications />
            </PrivateRoute>
          } />

          <Route path="/admin/dashboard" element={
            <AdminRoute>
              <AdminDashboard />
            </AdminRoute>
          } />

          <Route path="/admin/applications" element={
            <AdminRoute>
              <AdminApplications />
            </AdminRoute>
          } />
        </Routes>
      </div>
      <Toaster 
        position="bottom-right"
        toastOptions={{
          duration: 3000,
          style: {
            background: '#363636',
            color: '#fff',
          },
          success: {
            duration: 3000,
            iconTheme: {
              primary: '#4ade80',
              secondary: '#fff',
            },
          },
          error: {
            duration: 4000,
            iconTheme: {
              primary: '#ef4444',
              secondary: '#fff',
            },
          },
        }}
      />
    </Router>
  );
}

export default App;