import { useState, useCallback } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';

// Cache user data to prevent repeated fetches
const userCache = new Map<string, { name: string; timestamp: number }>();
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

export function useUsers() {
  const [loading, setLoading] = useState(false);

  const fetchUserName = useCallback(async (userId: string): Promise<string> => {
    try {
      // Check cache first
      const cached = userCache.get(userId);
      if (cached && Date.now() - cached.timestamp < CACHE_DURATION) {
        return cached.name;
      }

      setLoading(true);
      const userDoc = await getDoc(doc(db, 'users', userId));
      
      if (!userDoc.exists()) {
        throw new Error('User not found');
      }

      const userData = userDoc.data();
      const name = `${userData.firstName} ${userData.lastName}`;
      
      // Update cache
      userCache.set(userId, { name, timestamp: Date.now() });
      
      return name;
    } catch (error) {
      console.error('Error fetching user data:', error);
      return 'Unknown User';
    } finally {
      setLoading(false);
    }
  }, []);

  const clearCache = useCallback(() => {
    userCache.clear();
  }, []);

  return {
    fetchUserName,
    clearCache,
    loading
  };
}