import { cn } from '../../utils/cn';
import { ReactNode } from 'react';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'danger' | 'warning' | 'success';
  icon?: ReactNode;
}

const variants = {
  primary: 'bg-indigo-600 hover:bg-indigo-700 text-white',
  secondary: 'border-indigo-300 text-indigo-700 hover:bg-indigo-50',
  danger: 'bg-red-600 hover:bg-red-700 text-white',
  warning: 'border-orange-300 text-orange-700 hover:bg-orange-50',
  success: 'bg-green-600 hover:bg-green-700 text-white'
};

export function Button({ 
  children, 
  variant = 'primary',
  icon,
  className,
  ...props 
}: ButtonProps) {
  return (
    <button
      className={cn(
        'inline-flex items-center px-4 py-2 border rounded-md shadow-sm text-sm font-medium',
        'focus:outline-none focus:ring-2 focus:ring-offset-2',
        'disabled:opacity-50 transition-colors',
        variants[variant],
        className
      )}
      {...props}
    >
      {icon}
      {children}
    </button>
  );
}