import { sendEmailVerification } from 'firebase/auth';
import { auth } from '../lib/firebase';
import { useAuth } from '../hooks/useAuth';
import { useEffect } from 'react';
import toast from 'react-hot-toast';

export function EmailVerificationBanner() {
  const { checkVerification } = useAuth();

  // Check verification status every 30 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      checkVerification();
    }, 30000);

    return () => clearInterval(interval);
  }, [checkVerification]);

  const handleResendVerification = async () => {
    if (!auth.currentUser) return;
    try {
      await sendEmailVerification(auth.currentUser);
      toast.success('Verification email sent!');
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  return (
    <div className="bg-yellow-50 p-4 rounded-md mb-6">
      <div className="flex">
        <div className="flex-1">
          <p className="text-yellow-700">
            Your email is not verified. Please check your inbox and verify your email to access all features.{' '}
            <button
              onClick={handleResendVerification}
              className="text-yellow-800 underline hover:text-yellow-900"
            >
              Resend verification email
            </button>
          </p>
        </div>
      </div>
    </div>
  );
}