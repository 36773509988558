import { Pencil, CheckCircle2 } from 'lucide-react';

interface ProfileFormProps {
  firstName: string;
  lastName: string;
  email: string | null | undefined;
  isVerified: boolean;
  editMode: boolean;
  loading: boolean;
  onEdit: () => void;
  onCancel: () => void;
  onSubmit: (e: React.FormEvent) => Promise<void>;
  onChange: (field: 'firstName' | 'lastName', value: string) => void;
  onVerify: () => Promise<void>;
  onDeleteClick: () => void;
}

export function ProfileForm({
  firstName,
  lastName,
  email,
  isVerified,
  editMode,
  loading,
  onEdit,
  onCancel,
  onSubmit,
  onChange,
  onVerify,
  onDeleteClick
}: ProfileFormProps) {
  return (
    <div className="bg-white shadow rounded-lg p-6 mb-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-semibold text-gray-900">Personal Information</h2>
        {!editMode && (
          <button
            type="button"
            onClick={onEdit}
            className="inline-flex items-center px-4 py-2 rounded-md text-sm font-medium text-indigo-600 hover:bg-indigo-50 transition-colors duration-200"
          >
            Edit Profile
            <Pencil className="ml-2 h-4 w-4" />
          </button>
        )}
      </div>
      <form onSubmit={onSubmit} className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
              First Name
            </label>
            <input
              type="text"
              id="firstName"
              disabled={!editMode}
              className={`mt-1 px-4 h-12 block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 
                ${editMode 
                  ? 'bg-gray-50 border-gray-300 hover:border-gray-400' 
                  : 'bg-gray-100 border-transparent text-gray-900'}`}
              value={firstName}
              onChange={(e) => onChange('firstName', e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              disabled={!editMode}
              className={`mt-1 px-4 h-12 block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 
                ${editMode 
                  ? 'bg-gray-50 border-gray-300 hover:border-gray-400' 
                  : 'bg-gray-100 border-transparent text-gray-900'}`}
              value={lastName}
              onChange={(e) => onChange('lastName', e.target.value)}
            />
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Email
          </label>
          <div className="mt-1 flex items-center">
            <span className="block w-full h-12 px-4 rounded-md border border-gray-300 bg-gray-100 py-3 text-gray-900">
              {email}
            </span>
            {isVerified ? (
              <CheckCircle2 className="h-5 w-5 text-green-500 ml-2" />
            ) : (
              <button
                type="button"
                onClick={onVerify}
                className="ml-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Verify Now
              </button>
            )}
          </div>
        </div>
        {editMode ? (
          <div className="flex justify-end space-x-3 pt-2">
            <button
              type="button"
              onClick={onCancel}
              className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-100 rounded-md transition-colors duration-200"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 rounded-md shadow-sm disabled:opacity-50 transition-colors duration-200"
            >
              Save Changes
            </button>
          </div>
        ) : (
          <div className="flex justify-end pt-4">
            <button
              type="button"
              onClick={onDeleteClick}
              className="px-4 py-2 text-sm font-medium text-white bg-red-600 hover:bg-red-700 rounded-md shadow-sm transition-colors duration-200"
            >
              Delete Account
            </button>
          </div>
        )}
      </form>
    </div>
  );
}