import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { db } from '../lib/firebase';
import { collection, query, getDocs, orderBy } from 'firebase/firestore';
import { Application } from '../types/application';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '../components/ui/Tabs';
import { ApplicationsTable } from '../components/tables/ApplicationsTable';
import toast from 'react-hot-toast';

export function AdminApplications() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const statusFilter = searchParams.get('status') || 'all';
  
  const [applications, setApplications] = useState<Application[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadApplications();
  }, []);

  const loadApplications = async () => {
    try {
      setLoading(true);
      const applicationsRef = collection(db, 'applications');
      const q = query(applicationsRef, orderBy('updatedAt', 'desc'));
      const snapshot = await getDocs(q);
      const apps = await Promise.all(snapshot.docs.map(async (doc) => {
        const data = doc.data() as Application;
        return {
          id: doc.id,
          ...data,
          fulfillmentTime: data.completedAt ? 
            new Date(data.completedAt).getTime() - new Date(data.createdAt).getTime() : 
            undefined
        };
      }));

      setApplications(apps);
    } catch (error) {
      console.error('Error loading applications:', error);
      toast.error('Failed to load applications');
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (value: string) => {
    navigate(`/admin/applications?status=${value}`);
  };

  const getFilteredApplications = () => {
    if (statusFilter === 'all') return applications;
    return applications.filter(app => app.status === statusFilter);
  };

  const getStatusCounts = () => {
    return {
      all: applications.length,
      submitted: applications.filter(a => a.status === 'submitted').length,
      in_progress: applications.filter(a => a.status === 'in_progress').length,
      pending: applications.filter(a => a.status === 'pending').length,
      completed: applications.filter(a => a.status === 'completed').length,
      cancelled: applications.filter(a => a.status === 'cancelled').length
    };
  };

  const statusCounts = getStatusCounts();

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900">All Applications</h1>
        <p className="mt-2 text-gray-600">
          Monitor and manage all applications in the system
        </p>
      </div>

      <Tabs 
        value={statusFilter} 
        onValueChange={handleTabChange}
        className="space-y-6"
        as="div"
      >
        <TabsList>
          <TabsTrigger value="all">
            All ({statusCounts.all})
          </TabsTrigger>
          <TabsTrigger value="submitted">
            Submitted ({statusCounts.submitted})
          </TabsTrigger>
          <TabsTrigger value="in_progress">
            In Progress ({statusCounts.in_progress})
          </TabsTrigger>
          <TabsTrigger value="pending">
            Pending ({statusCounts.pending})
          </TabsTrigger>
          <TabsTrigger value="completed">
            Completed ({statusCounts.completed})
          </TabsTrigger>
          <TabsTrigger value="cancelled">
            Cancelled ({statusCounts.cancelled})
          </TabsTrigger>
        </TabsList>

        <TabsContent value="all">
          <ApplicationsTable applications={getFilteredApplications()} />
        </TabsContent>

        <TabsContent value="submitted">
          <ApplicationsTable applications={getFilteredApplications()} />
        </TabsContent>

        <TabsContent value="in_progress">
          <ApplicationsTable applications={getFilteredApplications()} />
        </TabsContent>

        <TabsContent value="pending">
          <ApplicationsTable applications={getFilteredApplications()} />
        </TabsContent>

        <TabsContent value="completed">
          <ApplicationsTable applications={getFilteredApplications()} />
        </TabsContent>

        <TabsContent value="cancelled">
          <ApplicationsTable applications={getFilteredApplications()} />
        </TabsContent>
      </Tabs>
    </div>
  );
}