import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { storage } from '../lib/firebase';

export async function uploadFile(userId: string, file: File, path: string) {
  if (file.size > 5 * 1024 * 1024) {
    throw new Error('File size must be less than 5MB');
  }

  const allowedTypes = [
    'application/pdf',
    'image/jpeg',
    'image/png',
    'image/gif',
    'text/plain',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];

  if (!allowedTypes.includes(file.type)) {
    throw new Error('Invalid file type. Only PDF, images, text, and Word documents are allowed.');
  }

  try {
    // Create storage reference
    const fileRef = ref(storage, `${userId}/${path}/${file.name}`);
    
    // Upload file
    const snapshot = await uploadBytes(fileRef, file);
    
    // Get download URL
    const downloadURL = await getDownloadURL(snapshot.ref);
    
    return {
      url: downloadURL,
      path: `${userId}/${path}/${file.name}`,
      fileName: file.name
    };
  } catch (error: any) {
    console.error('Error uploading file:', error);
    if (error.code === 'storage/unauthorized') {
      throw new Error('You do not have permission to upload files');
    }
    throw new Error(error.message || 'Failed to upload file');
  }
}

export async function deleteFile(path: string) {
  try {
    const fileRef = ref(storage, path);
    await deleteObject(fileRef);
  } catch (error: any) {
    console.error('Error deleting file:', error);
    if (error.code === 'storage/object-not-found') {
      // File doesn't exist, consider it deleted
      return;
    }
    throw new Error(error.message || 'Failed to delete file');
  }
}