import { Link } from 'react-router-dom';
import { User } from 'lucide-react';
import { GiTwoCoins } from 'react-icons/gi';
import { SignOutButton } from './SignOutButton';
import { UserProfile } from '../../hooks/useAuth';

interface NavLinksProps {
  userProfile: UserProfile | null;
  isMobile?: boolean;
}

export function NavLinks({ userProfile, isMobile = false }: NavLinksProps) {
  const commonLinkClasses = isMobile
    ? "block px-3 py-2 text-base font-medium text-white hover:text-white hover:bg-gray-800"
    : "text-white hover:text-white px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-800";

  return (
    <>
      {!isMobile && userProfile?.userType === 'customer' && (
        <Link
          to="/credits"
          className="flex items-center px-3 py-2 text-white hover:text-white"
        >
          <GiTwoCoins className="h-5 w-5 text-yellow-400" />
          <span className="ml-1">{userProfile.credits || 0} CP</span>
        </Link>
      )}

      <div className="flex items-center space-x-2">
        {userProfile?.userType === 'customer' && (
          <>
            <Link
              to="/job-profiles"
              className={commonLinkClasses}
            >
              Job Profiles
            </Link>
            <Link
              to="/applications"
              className={commonLinkClasses}
            >
              Applications
            </Link>
          </>
        )}

        {userProfile?.userType === 'agent' && (
          <>
            <Link
              to="/agent/dashboard"
              className={commonLinkClasses}
            >
              Dashboard
            </Link>
            <Link
              to="/agent/applications"
              className={commonLinkClasses}
            >
              Applications
            </Link>
          </>
        )}

        {userProfile?.userType === 'admin' && (
          <Link
            to="/admin/applications"
            className={commonLinkClasses}
          >
            Applications
          </Link>
        )}

        <Link
          to="/profile"
          className={isMobile ? commonLinkClasses : "text-white hover:text-white p-2 rounded-full hover:bg-gray-800"}
        >
          {isMobile ? (
            "Profile"
          ) : (
            <User className="h-5 w-5" />
          )}
        </Link>

        {isMobile ? (
          <SignOutButton variant="full" className={commonLinkClasses} />
        ) : (
          <SignOutButton />
        )}
      </div>
    </>
  );
}