import { RotateCw, Play } from 'lucide-react';
import { Application } from '../../../types/application';
import { Button } from '../../ui/Button';

interface ApplicationActionsProps {
  application: Application;
  isCustomer: boolean;
  isAssignedAgent: boolean;
  canResubmit: boolean;
  isAdmin: boolean;
  updating: boolean;
  onCancel: () => Promise<void>;
  onResubmit: () => Promise<void>;
  onPickUp: () => Promise<void>;
  onUpdateStatus: (status: 'completed' | 'pending' | 'in_progress') => Promise<void>;
}

export function ApplicationActions({
  application,
  isCustomer,
  isAssignedAgent,
  canResubmit,
  isAdmin,
  updating,
  onCancel,
  onResubmit,
  onPickUp,
  onUpdateStatus
}: ApplicationActionsProps) {
  return (
    <div className="flex space-x-3">
      {/* Customer Actions */}
      {isCustomer && (
        <>
          {application.status === 'submitted' && (
            <Button
              variant="danger"
              onClick={onCancel}
              disabled={updating}
            >
              Cancel Application
            </Button>
          )}
          {canResubmit && (
            <Button
              variant="primary"
              onClick={onResubmit}
              disabled={updating}
              icon={<RotateCw className="h-4 w-4 mr-2" />}
            >
              Resubmit Application
            </Button>
          )}
        </>
      )}

      {/* Admin Actions */}
      {isAdmin && application.status === 'submitted' && (
        <Button
          variant="danger"
          onClick={onCancel}
          disabled={updating}
        >
          Cancel & Refund Application
        </Button>
      )}

      {/* Agent Actions */}
      {!isCustomer && !isAdmin && (
        <>
          {application.status === 'submitted' && (
            <Button
              variant="primary"
              onClick={onPickUp}
              disabled={updating}
            >
              Pick Up Application
            </Button>
          )}
          {isAssignedAgent && (
            <>
              {application.status === 'in_progress' && (
                <>
                  <Button
                    variant="warning"
                    onClick={() => onUpdateStatus('pending')}
                    disabled={updating}
                  >
                    Mark as Pending
                  </Button>
                  <Button
                    variant="success"
                    onClick={() => onUpdateStatus('completed')}
                    disabled={updating}
                  >
                    Mark as Complete
                  </Button>
                </>
              )}
              {(application.status === 'pending' || application.status === 'resubmitted') && (
                <Button
                  variant="primary"
                  onClick={() => onUpdateStatus('in_progress')}
                  disabled={updating}
                  icon={<Play className="h-4 w-4 mr-2" />}
                >
                  Resume Processing
                </Button>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}