import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

interface LogoProps {
  onClick?: (e: React.MouseEvent) => void;
}

export function Logo({ onClick }: LogoProps) {
  const { userProfile } = useAuth();
  const navigate = useNavigate();

  const handleLogoClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!userProfile) {
      navigate('/');
      return;
    }

    // Use React Router navigation instead of window.location.reload()
    switch (userProfile.userType) {
      case 'admin':
        navigate('/admin/dashboard');
        break;
      case 'agent':
        navigate('/agent/dashboard');
        break;
      default:
        navigate('/dashboard');
    }
  };

  return (
    <a 
      href="#"
      onClick={handleLogoClick}
      className="flex items-center px-1 py-1 rounded-md transition-all duration-200 hover:bg-gray-800"
    >
      <img 
        src="/logo.png"
        alt="BlitzApply" 
        className="h-10"
      />
    </a>
  );
}