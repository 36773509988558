import { cn } from '../../../utils/cn';
import { FileText, Briefcase, MessageSquare } from 'lucide-react';

export const sections = [
  { id: 'details', label: 'Application Details', icon: FileText },
  { id: 'profile', label: 'Job Profile', icon: Briefcase },
  { id: 'comments', label: 'Comments', icon: MessageSquare }
] as const;

interface SectionNavProps {
  activeSection: string;
  onSectionClick: (sectionId: string) => void;
}

export function SectionNav({ activeSection, onSectionClick }: SectionNavProps) {
  return (
    <div className="sticky top-0 z-10 bg-gray-50 border-b border-gray-200 mb-8">
      <div className="max-w-7xl mx-auto">
        <nav className="flex space-x-8" aria-label="Sections">
          {sections.map(({ id, label, icon: Icon }) => (
            <button
              key={id}
              onClick={() => onSectionClick(id)}
              className={cn(
                "flex items-center px-3 py-4 text-sm font-medium border-b-2 transition-colors",
                activeSection === id
                  ? "border-indigo-500 text-indigo-600"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
              )}
            >
              <Icon className="h-4 w-4 mr-2" />
              {label}
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
}