import { useCallback } from 'react';
import { useAuth } from './useAuth';
import { db } from '../lib/firebase';
import { collection, doc, writeBatch, increment } from 'firebase/firestore';
import { Application } from '../types/application';
import toast from 'react-hot-toast';

export function useApplicationActions() {
  const { user } = useAuth();

  const submitApplication = useCallback(async (data: Omit<Application, 'id' | 'createdAt' | 'updatedAt'>) => {
    if (!user) {
      throw new Error('User not authenticated');
    }

    const batch = writeBatch(db);
    const now = new Date().toISOString();

    // Create application document
    const applicationRef = doc(collection(db, 'applications'));
    const applicationData = {
      ...data,
      createdAt: now,
      updatedAt: now,
      lastActionAt: now,
      lastActionBy: user.uid,
      comments: []
    };

    batch.set(applicationRef, applicationData);

    // Update user's credits
    const userRef = doc(db, 'users', user.uid);
    batch.update(userRef, {
      credits: increment(-3)
    });

    try {
      await batch.commit();
      toast.success('Application submitted successfully');
      return {
        id: applicationRef.id,
        ...applicationData
      };
    } catch (error: any) {
      console.error('Error submitting application:', error);
      if (error.code === 'permission-denied') {
        throw new Error('Insufficient credits or permissions');
      }
      throw new Error('Failed to submit application. Please try again.');
    }
  }, [user]);

  return {
    submitApplication
  };
}